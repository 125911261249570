import React from 'react';
import { HeaderWrapper, Logo } from './style';
import LangSelector from './LangSelector';

const Header = (props) => {
  return (
      <HeaderWrapper><Logo>{props.title}</Logo><LangSelector /></HeaderWrapper>
  )
}

export default Header;