import styled from 'styled-components';
import titlePic from '../static/title.png';
import titleBack from '../static/title-back.png';

export const HeaderWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 72px;
    background: url(${titleBack});
`

export const Logo = styled.div`
    position: absolute;
    width: 300px;
    height: 72px;
    margin-left: 42%;
    top: 0;
    display: block;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    font-family: palatino, serif;
    padding: 18px 0;
    color: #fff;
    // background: url(${titlePic});
    // background-size: contain;
`