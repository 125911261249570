import React from 'react';
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from 'react-i18next';
import Card from "./Card";
import Carousel from "./Carousel";
import AddLogoImg from "../static/add_logo.png";
import DynamicImg from "../static/kangaroo.gif";
import ColofulImg from "../static/colorful.png";
import PrivacyImg from "../static/privacy.jpeg";

function Promo() {
  const { t, i18n } = useTranslation();

  let cards = [
    {
      key: uuidv4(),
      content: (
        <Card cardTitle={t('qrcode.promo_logo')} cardContent={t('qrcode.promo_logo_content')} bkColor="linear-gradient(to top right, #FFF9C4, white)" image={AddLogoImg}/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card cardTitle={t('qrcode.promo_dynamic')} cardContent={t('qrcode.promo_dynamic_content')} bkColor="linear-gradient(to top right, #FFA000, white)" image={DynamicImg}/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card cardTitle={t('qrcode.promo_colorful')} cardContent={t('qrcode.promo_colorful_content')} bkColor="linear-gradient(to top right, #86D0F2, white)" image={ColofulImg}/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card cardTitle={t('qrcode.promo_privacy')} cardContent={t('qrcode.promo_privacy_content')} bkColor="linear-gradient(to top right, #D2DC68, white)" image={PrivacyImg}/>
      )      
    }
    // {
    //   key: uuidv4(),
    //   content: (
    //     <Card cardTitle="Choose the model" cardContent="Select the model from micro to M2" bkColor="linear-gradient(to top right, #D2DC68, white)"/>
    //   )
    // },
    // {
    //   key: uuidv4(),
    //   content: (
    //     <Card cardTitle="Contact us" cardContent="Feel free to contact us &#10;&#13; - For cooperation &#10; - To give us advice &#10; - Or anything" bkColor="linear-gradient(to top right, #F4C9D7, white)"/>
    //   )
    // }
  ];
  return (
    <div className="">
      <Carousel
        cards={cards}
        height="35vh"
        width="65vw"
        margin="0 auto"
        offset={2}
        showArrows={false}
      />
    </div>
  );
}

export default Promo;