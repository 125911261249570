import styled from 'styled-components';

export const MainContent = styled.div`
  margin: 2vh 17.5vw;
  width: 65vw;
  display: flex;
  overflow: hidden;
`;

export const QrcodeForm = styled.form`
  // flex: 0 0 40vw;
  margin: 0 40px 0 3px;
  padding: 3vh 3vw;
  // flex: 1;
  width: 52vw;
  background-color: #fafafa;
  opacity: 0.7;
  box-shadow: 5px 5px #b0bec5;

`;

export const OutputZone = styled.div`
  width: 14vw;
  background-color: transparent;
`;

export const PictureFrame = styled.div`
  border: 1vw ridge #fafafa;
  border-radius: 1vw;
  width: 12vw;
  height: 12vw;
`;

export const AdvancedPanel = styled.div`
  width: 100%;

`;

export const AdvancedButton = styled.button`
  border: none;
  margin: 10px 20px 5px 10px;
  font-size: 21px;
  font-weight: bold;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  color: #2E7D32;
  background-color: transparent;
  &:hover{
    color: #29B6F6;
    cursor: pointer;
  }
  
`;

export const HiddenPanel = styled.div`
  width: 100%;
  margin-top: 3vh;
`;

export const QrFormLable = styled.label`
  padding: 15px;
  fontSize: 1rem;
  fontFamily: "Roboto","Helvetica","Arial",sans-serif;
  fontWeight: bold;
  &.input {
    height: 56px;
    line-height: 56px;
  }
`;

export const ColorContainer = styled.div`
  margin: 0;
  display: flex;
  flexwrap: nowrap;
`;

export const FileNameSpan = styled.span`
  margin-left: 15px;
  position: absolute;
  height: 56px;
  // line-height: 56px;
  display: inline-flex;
  align-items: center;
  width: 22vw;
  color: #1a1a1a;
`;

export const ErrorMsgDiv = styled.div`
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: red;
`;