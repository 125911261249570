import styled from 'styled-components';
import audioWave from '../static/audio_wave.gif';

export const MainContent = styled.div`
  margin: 5vh 0;
`;

export const LanguagePanel = styled.div`
  margin: 5vh 17.5vw;
  width: 65vw;
  height: 56px;
  display: flex;
  overflow: visable;
  justify-content: space-between;
`;

export const LeftColumn = styled.div`
  width: 29vw;
  margin-right: 7vw;
  display: flex;
  justify-content: space-between;
`;

export const RightColumn = styled.div`
  width: 29vw;
  display: flex;
  justify-content: space-between;
`;

export const TranslatorLabel = styled.label`
  fontSize: 1rem;
  fontFamily: "Roboto","Helvetica","Arial",sans-serif;
  fontWeight: bold;
  height: 56px;
  line-height: 56px;
  // margin-right: 15px;
`;

export const ActionPanel = styled.div`
  margin: 5vh 17.5vw;
  width: 65vw;
  display: flex;
`;

export const AudioWaveBar = styled.div`
  width: 18vw;
  height: 52px;
  background: url(${audioWave});
  background-position: center;
  margin: 0 auto;
`;

export const TextPanel = styled.div`
  margin: 5vh 17.5vw;
  width: 65vw;
  display: flex;
  justify-content: space-between;
`;

