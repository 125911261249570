import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { createGlobalStyle } from 'styled-components';
import axios from "axios";
import IconButton from '@mui/material/IconButton';
import MicRoundedIcon from '@mui/icons-material/MicRounded';
import StopCircleRoundedIcon from '@mui/icons-material/StopCircleRounded';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import CampaignIcon from '@mui/icons-material/Campaign';

import Header from '../header';
import Contact from '../contactBoard/Contact';
import { ContentWrapper } from '../style';
import * as actionCreator from './store/actionCreators';
import LanguageGrid from './LanguageGrid';
import {
    MainContent,
    LanguagePanel,
    ActionPanel,
    TextPanel,
    TranslatorLabel,
    LeftColumn,
    RightColumn,
    AudioWaveBar,
  } from './style';

const backendUrl = "https://spring-cat.online";

const AudioTranslator = (props) => {
  const { t, i18n } = useTranslation();
  const GlobalStyles = createGlobalStyle`
    html {
      height: 100%;
      background: linear-gradient(90deg, rgba(234,232,245,1) 0%, rgba(243,242,247,1) 52%, rgba(239,236,246,1) 100%);
    }
  `;

  const [recognition, setRecognition] = useState(null);
  const [timer, setTimer] = useState(null);
  const startRecording = () => {
    const rec = new window.webkitSpeechRecognition();
    rec.continuous = true;
    rec.interimResults = true;
    if (props.sourceLang) {
      rec.lang = props.sourceLang;
    }

    rec.onstart = () => {
      props.toggleIsRecording(true);
      props.changeSourceText("");
      const timer = setInterval(() => {
        if (recognition) {
          recognition.stop();
          props.toggleIsRecording(false);
        }
        clearInterval(timer);
      }, 30000);
      setTimer(timer);
    };

    rec.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");
      
      props.changeSourceText(String(transcript));
    };

    rec.start();
    setRecognition(rec);
  };

  const stopRecording = () => {
    if (recognition) {
      recognition.stop();
      props.toggleIsRecording(false);
    }
    if (timer) {
      clearInterval(timer);
    }
  };

  const doTranslation = async(e) => {
    e.preventDefault();

    const translateApi = `${backendUrl}/api/v0/translate`;
    // const translateApi = `http://localhost:5000/api/v0/translate`;

    const payload = {
      "text": props.sourceText,
      "sourceLang": props.sourceLang,
      "targetLang": props.targetLang
    };
    const headers = {"Content-Type": "application/json"}
    try {
      const response = await axios({
        method: "post",
        url: translateApi,
        data: payload,
        headers: headers,
      });
      if (response.data && response.data.result) {
        props.changeResultText(response.data.result);
      }
    } catch(e) {
      console.log(e);
    }
    
  };

  return (
    <Fragment>
      <Helmet>
        <title>{t('meta.translator.title')}</title>
        <meta name="description" content={t('meta.translator.description')} />
      </Helmet>
      <GlobalStyles />
      <Header title="Audio Translator" />
      <ContentWrapper>
        <Contact />
        <MainContent>
          <LanguagePanel>
            <LeftColumn>
              <TranslatorLabel>{t('translator.source_language')} :</TranslatorLabel>
              <LanguageGrid name='source-lang-selector' value={props.sourceLang} onChange={props.changeSourceLang} style={{ marginRight: '5vw' }}/>
            </LeftColumn>
            <RightColumn>
              <TranslatorLabel>{t('translator.target_language')} :</TranslatorLabel>
              <LanguageGrid name='target-lang-selector' value={props.targetLang} onChange={props.changeTargetLang} />          
            </RightColumn>
          </LanguagePanel>
          <ActionPanel>
            <LeftColumn>
              <IconButton size="large" title={t('translator.start_record')} onClick={startRecording}>
                <MicRoundedIcon fontSize="inherit" />
              </IconButton>
              <AudioWaveBar style={{ visibility: props.isRecording ? 'visible' : 'hidden'}} />
              <IconButton size="large" title={t('translator.stop_record')} onClick={stopRecording}>
                <StopCircleRoundedIcon fontSize="inherit" />
              </IconButton>
            </LeftColumn>
            <RightColumn>
              <Button type="button" variant="contained" size="large" onClick={doTranslation}>{t('translator.translate')}</Button>
            </RightColumn>
          </ActionPanel>
          <TextPanel>
            <TextField
              id="outlined-textarea"
              label={t('translator.check_input')}
              minRows={6}
              maxRows={15}
              sx={{ width: '29vw'}}
              value={props.sourceText}
              placeholder={t('translator.check_input')}
              onChange={(e) => props.changeSourceText(e.target.value)}
              multiline
            />
            <TextField
              id="outlined-textarea"
              label={t('translator.translation_result')}
              minRows={6}
              maxRows={15}
              sx={{ width: '29vw'}}
              value={props.resultText}
              placeholder={t('translator.translation_result')}
              onChange={props.changeResultText}
              multiline
            />
          </TextPanel>
          <Alert severity="warning" icon={<CampaignIcon fontSize="inherit" />} sx={{ width: 'calc(65vw - 32px)', margin: 'auto'}}>
            {t('translator.attention')}
          </Alert>      
        </MainContent>
      </ContentWrapper>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    sourceLang: state.translator.get('sourceLang'),
    targetLang: state.translator.get('targetLang'),
    isRecording: state.translator.get('isRecording'),
    sourceText: state.translator.get('sourceText'),
    resultText: state.translator.get('resultText'),
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSourceLang(value) {
      dispatch(actionCreator.changeSourceLang(value));
    },
    changeTargetLang(value) {
      dispatch(actionCreator.changeTargetLang(value));
    },
    toggleIsRecording(value) {
      dispatch(actionCreator.toggleIsRecording(value));
    },
    changeSourceText(value) {
      dispatch(actionCreator.changeSourceText(value));
    },
    changeResultText(value) {
      dispatch(actionCreator.changeResultText(value));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioTranslator);
