import { fromJS } from 'immutable';
import * as actionType from './actionTypes';
import PreviewPicture from '../../static/preview.png';

const defaultState = fromJS({
  outputFormat: "png",
  contentText: "",
  showSettings: false,
  scale: 5,
  dataDark: {
    r: '0',
    g: '0',
    b: '0',
    a: '1',    
  },
  dataLight: {
    r: '255',
    g: '255',
    b: '255',
    a: '1',
  },
  disableUploadLogo: false,
  disableUploadBackground: false,
  disableDownload: true,
  qrLogo: null,
  qrBackground: null,
  errorMsg: {
    addLogoError: null,
    addBackgroundError: null,
  },
  previewPic: PreviewPicture,
  downloadPic: null,
});

export default (state = defaultState, action) => {
    switch (action.type) {
      case actionType.CHANGE_OUTPUT_FORMAT:
        return state.set('outputFormat', action.value);
      case actionType.CHANGE_CONTENT_TEXT:
        return state.set('contentText', action.value);
      case actionType.TOGGLE_SHOW_SETTINGS:
        if (action.value === true) {
          return state.set('showSettings', false);
        } else {
          return state.set('showSettings', true);
        }
      case actionType.CHANGE_SCALE:
        return state.set('scale', action.value);
      case actionType.CHANGE_DARK:
        return state.set('dark', action.value);
      case actionType.CHANGE_DATA_DARK:
        return state.set('dataDark', action.value);
      case actionType.CHANGE_DATA_LIGHT:
        return state.set('dataLight', action.value);
      case actionType.TOGGLE_LOGO_BUTTON:
        return state.set('disableUploadLogo', action.value);
      case actionType.TOGGLE_BACKGROUND_BUTTON:
        return state.set('disableUploadBackground', action.value);
      case actionType.TOGGLE_DOWNLOAD_BUTTON:
        return state.set('disableDownload', action.value);
      case actionType.CHANGE_QR_LOGO:
        return state.set('qrLogo', action.value);
      case actionType.CHANGE_QR_BACKGROUND:
        return state.set('qrBackground', action.value);
      case actionType.CHANGE_ADD_LOGO_ERROR:
        return state.setIn(['errorMsg', 'addLogoError'], action.value);
      case actionType.CHANGE_ADD_BACKGROUND_ERROR:
        return state.setIn(['errorMsg', 'addBackgroundError'], action.value);
      case actionType.CHANGE_PREVIEW_PICTURE:
        return state.set('previewPic', action.value);
      case actionType.CHANGE_DOWNLOAD_PICTURE:
        return state.set('downloadPic', action.value);
      default:
        return state;

    }
    // if (action.type === actionType.CHANGE_OUTPUT_FORMAT) {
    //   const newState = JSON.parse(JSON.stringify(state));
    //   newState.outputFormat = action.value;
    //   return newState;
        
    // }
    // if (action.type === actionType.CHANGE_CONTENT_TEXT) {
    //   const newState = JSON.parse(JSON.stringify(state))
    //   newState.contentText = action.value;
    //   return newState;
        
    // }
    // if (action.type === actionType.CHANGE_SCALE) {
    //   const newState = JSON.parse(JSON.stringify(state));
    //   newState.scale = action.value;
    //   return newState;
        
    // }
    // return state;
}