import * as actionType from './actionTypes';

export const changeOutputFormat = (value) => ({
    'type': actionType.CHANGE_OUTPUT_FORMAT,
    'value': value
});

export const changeContentText= (value) => ({
    'type': actionType.CHANGE_CONTENT_TEXT,
    'value': value
});

export const changeScale = (value) => ({
    'type': actionType.CHANGE_SCALE,
    'value': value
});

export const toggleShowSettings = (value) => ({
    'type': actionType.TOGGLE_SHOW_SETTINGS,
    'value': value
});

export const changeDark = (value) => ({
    'type': actionType.CHANGE_DARK,
    'value': value
});

export const changeDataDark = (value) => ({
    'type': actionType.CHANGE_DATA_DARK,
    'value': value
});

export const changeDataLight = (value) => ({
    'type': actionType.CHANGE_DATA_LIGHT,
    'value': value
});

export const changeQrLogo = (value) => ({
    'type': actionType.CHANGE_QR_LOGO,
    'value': value
});

export const changeQrBackground = (value) => ({
    'type': actionType.CHANGE_QR_BACKGROUND,
    'value': value
});

export const changeAddLogoError = (value) => ({
    'type': actionType.CHANGE_ADD_LOGO_ERROR,
    'value': value
});

export const changeAddBackgroundError = (value) => ({
    'type': actionType.CHANGE_ADD_BACKGROUND_ERROR,
    'value': value
});

export const changePreviewPic = (value) => ({
    'type': actionType.CHANGE_PREVIEW_PICTURE,
    'value': value
});

export const changeDownloadPic = (value) => ({
    'type': actionType.CHANGE_DOWNLOAD_PICTURE,
    'value': value
});

export const toggleLogoButton = (value) => ({
    'type': actionType.TOGGLE_LOGO_BUTTON,
    'value': value
});

export const toggleBackgroundButton = (value) => ({
    'type': actionType.TOGGLE_BACKGROUND_BUTTON,
    'value': value
});

export const toggleDownloadButton = (value) => ({
    'type': actionType.TOGGLE_DOWNLOAD_BUTTON,
    'value': value
});
