import * as actionType from './actionTypes';

export const changeContactEmail = (value) => ({
  'type': actionType.CHANGE_CONTACT_EMAIL,
  'value': value
});

export const changeContactTitle = (value) => ({
  'type': actionType.CHANGE_CONTACT_TITLE,
  'value': value
});

export const changeContactContent = (value) => ({
  'type': actionType.CHANGE_CONTACT_CONTENT,
  'value': value
});