import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Header from './header';
import Promo from './carousel/Promo';
import { ContentWrapper } from './style';
import QrcodePanel from './qrcodePanel/QrcodePanel';
import Contact from './contactBoard/Contact';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { createGlobalStyle } from 'styled-components';
import garden from './static/qrgarden.png';


const GlobalStyles = createGlobalStyle`
  html {
    height: 100%;
    background: url(${garden});
    background-attachment: fixed;
    background-size: 100vw 100vh;
  }
`;

class Qrcode extends Component {
  handleShowPromo (toggle) {
    if (!toggle) {
      return (
        <Promo />     
      );
    }
  }
  
  render() {
    const { t } = this.props;

    return(
    <Fragment>
      <Helmet>
        <title>{t('meta.qrcode.title')}</title>
        <meta name="description" content={t('meta.qrcode.description')} />
      </Helmet>
      <GlobalStyles />
      <Header title="QR Garden" />
      <ContentWrapper>
      <Contact />
      {this.handleShowPromo(this.props.hidePromo)}
      <QrcodePanel />
      </ContentWrapper>
    </Fragment>);
  }
}

const mapStateToProps = (state) => {
  return {
    hidePromo: state.qrcode.get('showSettings'),
  }
};


export default connect(mapStateToProps)(withTranslation()(Qrcode));