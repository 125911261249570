import styled from 'styled-components';
import contact_background from '../static/contact_bg.png';

export const ContactDiv = styled.div`
    margin-top: 0;
    margin-right: 20px;
    width: 210px;
    height: 163px;
    background: url(${contact_background});
    background-size: 210px 163px;
    float: right;
    z-index: 99;
`

export const InnerDiv = styled.div`
    font-size: 24px;
    font-weight: bold;
    padding: 80px 27px 80px 80px;
    &:hover {
        cursor: pointer;
    }
`