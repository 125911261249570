export const CHANGE_OUTPUT_FORMAT = 'qrcode/change_output_format';
export const CHANGE_CONTENT_TEXT = 'qrcode/change_content_text';
export const TOGGLE_SHOW_SETTINGS = 'qrcode/toggle_show_settings';
export const CHANGE_SCALE = 'qrcode/change_scale';
export const CHANGE_DARK = 'qrcode/change_dark';
export const CHANGE_DATA_DARK = 'qrcode/change_data_dark';
export const CHANGE_DATA_LIGHT = 'qrcode/change_data_light';
export const CHANGE_QR_LOGO = 'qrcode/change_qr_logo';
export const CHANGE_QR_BACKGROUND = 'qrcode/change_qr_background';
export const CHANGE_ADD_LOGO_ERROR = 'qrcode/change_add_logo_error';
export const CHANGE_ADD_BACKGROUND_ERROR = 'qrcode/change_add_background_error';
export const CHANGE_PREVIEW_PICTURE = 'qrcode/change_preview_picture';
export const CHANGE_DOWNLOAD_PICTURE = 'qrcode/change_download_picture';
export const TOGGLE_LOGO_BUTTON = 'qrcode/toggle_logo_button';
export const TOGGLE_BACKGROUND_BUTTON = 'qrcode/toggle_background_button';
export const TOGGLE_DOWNLOAD_BUTTON = 'qrcode/toggle_download_button';

