import React, { useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from "axios";

// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import { MainContent, QrcodeForm, OutputZone, PictureFrame, AdvancedPanel, AdvancedButton, HiddenPanel, QrFormLable, ColorContainer, FileNameSpan, ErrorMsgDiv } from './style';
import * as actionCreator from './store/actionCreators';
import { DarkPicker, DataDarkPicker, DataLightPicker } from './ColorPicker';
import previewPicture from '../static/preview.png';
// import Banner from '../AdBanner';


const backendUrl = "https://spring-cat.online";

const QrcodePanel = (props) => {
  const addLogoInput = useRef(null);
  const addBackgroundInput = useRef(null);
  const { t, i18n } = useTranslation();

  const handleAddLogo = (e) => {
    addLogoInput.current.click();
  }

  const handleAddBackground = (e) => {
    addBackgroundInput.current.click();
  }

  const handleChangeLogo = (e) => {
    const uploadedLogo = e.target.files[0];
    if (!uploadedLogo) {
      props.changeQrLogo(null);
      if (props.disableUploadBackground === true) {
        props.toggleBackgroundButton(false);
      }
      return;
    }
    if (!checkFileSize(uploadedLogo)) {
      props.changeAddLogoError("Image size can not exceed 5Mb.");
      return;
    } else {
      props.changeAddLogoError(null);
    }

    if (!uploadedLogo.type.match("image/jpeg")) {
      props.changeAddLogoError("Please upload an '.jpg' image.");
      return;
    } else {
      props.changeAddLogoError(null);
    }
    props.changeQrLogo(uploadedLogo);
    props.toggleBackgroundButton(true);
    if (props.qrBackground) {
      props.changeQrBackground(null);
    }
  }

  const handleChangeBackground = (e) => {
    const backgroundImg = e.target.files[0];
    if (!backgroundImg) {
      props.changeQrBackground(null);
      if (props.disableUploadLogo === true) {
        props.toggleLogoButton(false);
      }
      return;
    }
    if (!checkFileSize(backgroundImg)) {
      props.changeAddBackgroundError("Image size can not exceed 5Mb.");
      return;
    } else {
      props.changeAddBackgroundError(null);
    }
    if (!backgroundImg.type.match("image/jpeg|image/gif")) {
      props.changeAddBackgroundError("Please upload an image of format .jpg or .gif");
      return;
    } else {
      props.changeAddBackgroundError(null);
    }
    props.changeQrBackground(backgroundImg);
    props.toggleLogoButton(true);
    if (props.qrLogo) {
      props.changeQrLogo(null);
    }
  }

  const checkFileSize = (f) => {
    const MAX_FILE_SIZE = 5120;
    if ((f.size / 1024) > MAX_FILE_SIZE) {      
      return false;
    } else {    
      return true;
    }
  }

  // const checkMimeType = (f)  => {
  //   if (f.type.match("image/*")) {
  //     return true;
  //   }
  //   return false;
  // }

  const handleShowError = (err) => {
    if (err) {
      return (<ErrorMsgDiv>{err}</ErrorMsgDiv>);
    }
  }

  const handleGenerate = async(e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("outputFormat", props.outputFormat);
    formData.append("contentText", props.contentText);
    formData.append("scale", props.scale);
    if (typeof props.dark === "string") {
      formData.append("dark", props.dark);
    }
    if (typeof props.dataDark === "string") {
      formData.append("dataDark", props.dataDark);
    }
    if (typeof props.dataLight === "string") {
      formData.append("dataLight", props.dataLight);
    }
    if (props.qrLogo) {
      formData.append("qrLogo", props.qrLogo);
    }
    if (props.qrBackground) {
      formData.append("qrBackground", props.qrBackground);
    }
    try {
      const response = await axios({
        method: "POST",
        // url: `${backendUrl}/api/v0/generate_qrcode`,
        url: `http://127.0.0.1:5000/api/v0/generate_qrcode`,
        data: formData,
        responseType: 'blob',
        headers: {"Content-Type": "multipart/form-data"},
      });
      if (response.status == 200) {
        if (response.data) {
          const mime_type = response.data.type;
          if (mime_type ==="image/jpeg") {
            props.changeOutputFormat("jpg");
          }
          else if (mime_type === "image/gif") {
            props.changeOutputFormat("gif");
          }
          // if (props.outputFormat == "png") {
          //   mime_type = "image/png";
          // } else if (props.outputFormat == "eps") {
          //   mime_type = "application/postscript";
          // } else if (props.outputFormat == "svg") {
          //   mime_type = "image/svg+xml";
          // }
          // else {
          //   alert("Failed to generate QR code with unknown format.");
          //   return;
          // }

          const blob_obj = new Blob([response.data], {type: mime_type});
          const downloadUrl = URL.createObjectURL(blob_obj);
          props.changePreviewPic(downloadUrl);
          props.changeDownloadPic(downloadUrl);
          if (props.disableUploadLogo === true) {
            props.toggleLogoButton(false);
          }
          if (props.disableUploadBackground === true) {
            props.toggleBackgroundButton(false);
          }
          props.toggleDownloadButton(false);
        }
      }
    } catch(error) {
      console.log(error);
      alert("Failed to generate QR code.")
    }

  }

  const handleDownload = async(e) => {
    e.preventDefault();
    try {
      const link = document.createElement('a');
      link.href = props.downloadPic;
      link.download = 'qrcode.' + props.outputFormat;
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      URL.revokeObjectURL(props.downloadPic);
      props.changeDownloadPic(null);
      props.changePreviewPic(previewPicture);
      props.toggleDownloadButton(true);
    }
    catch (error) {
      if (props.downloadPic) {
      URL.revokeObjectURL(props.downloadPic);
      props.changeDownloadPic(null);
      props.changePreviewPic(previewPicture);
      }
      alert('Failed to download the QR code.')
    }
  }

  return (
    <Fragment>
      {/* <Banner /> */}
      <MainContent>
        <QrcodeForm>
          
          {/* <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={props.outputFormat}
              onChange={props.changeOutputFormat}
            >
              <QrFormLable>Output Format</QrFormLable>
              <FormControlLabel value="png" control={<Radio />} label="png" />
              <FormControlLabel value="svg" control={<Radio />} label="svg" />
              <FormControlLabel value="eps" control={<Radio />} label="eps" /> 
              <FormControlLabel value="txt" control={<Radio />} label="txt" />
            </RadioGroup>
          </FormControl> */}
          
          <TextField
            id="outlined-textarea"
            label={t('qrcode.write_content_placeholder')}
            fullWidth
            minRows={2}
            maxRows={4}
            value={props.contentText}
            placeholder={t('qrcode.write_content_placeholder')}
            onChange={props.changeContentText}
            multiline
          />
        
          <AdvancedPanel>
            <AdvancedButton type="button" onClick={()=>props.toggleShowSettings(props.showSettings)}>{t('qrcode.advanced_settings')}</AdvancedButton>
            <hr />
            <HiddenPanel style={{display: props.showSettings ? "block" : "none"}}>
              <QrFormLable className='input'>{t('qrcode.scale')}</QrFormLable>
              <FormControl >              
                <InputLabel id="demo-simple-select-label">{t('qrcode.scale')}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.scale}
                  label={t('qrcode.scale')}
                  onChange={props.changeScale}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                </Select>
              </FormControl>
              <br/>
              <ColorContainer>
                <QrFormLable className='input'>{t('qrcode.color')}</QrFormLable>      
                <QrFormLable className='input'>{t('qrcode.dark')}</QrFormLable><DarkPicker changeDark={props.changeDark}/>
                <QrFormLable className='input'>{t('qrcode.data_dark')}</QrFormLable><DataDarkPicker changeDataDark={props.changeDataDark}/>
                <QrFormLable className='input'>{t('qrcode.data_light')}</QrFormLable><DataLightPicker changeDataLight={props.changeDataLight}/>
              </ColorContainer>
              <QrFormLable className='input'>{t('qrcode.add_logo')}</QrFormLable>
              <Button type="button" variant="outlined" onClick={handleAddLogo} disabled={props.disableUploadLogo}>{t('qrcode.upload_logo')}</Button><FileNameSpan>{props.qrLogo ? props.qrLogo.name: t('qrcode.add_logo_hint')}</FileNameSpan>
              <input type="file" ref={addLogoInput} accept="image/jpeg" onChange={handleChangeLogo} hidden></input>
              <br/>
              {handleShowError(props.addLogoError)}

              <QrFormLable className='input'>{t('qrcode.add_background')}</QrFormLable>
              <Button type="button" variant="outlined" onClick={handleAddBackground} disabled={props.disableUploadBackground}>{t('qrcode.upload_background')}</Button><FileNameSpan>{props.qrBackground ? props.qrBackground.name: t('qrcode.upload_background_hint')}</FileNameSpan>
              <input type="file" ref={addBackgroundInput} accept="image/*" onChange={handleChangeBackground} hidden />
              <br/>
              {handleShowError(props.addBackgroundError)}
              <p style={{padding: '15px'}}>{t('qrcode.advanced_setting_hint')}</p>
            </HiddenPanel>

          </AdvancedPanel>
        </QrcodeForm> 

        <OutputZone>
          <PictureFrame ><img src={props.previewPic} style={{width: "100%", height: "100%" }}/></PictureFrame>
          <Button type="button" variant="contained" style={{margin:"3vh 2vw", width: "10vw"}} color="success" onClick={handleGenerate}>{t('qrcode.generate')}</Button>
          <Button type="button" variant="contained" style={{ margin:"0 2vw", width: "10vw"}} onClick={handleDownload} disabled={props.disableDownload}>{t('qrcode.download')}</Button>
        </OutputZone>
        
      </MainContent>
    
    </Fragment>
  )
}


const mapStateToProps = (state) => {
  return {
    contentText: state.qrcode.get('contentText'),
    outputFormat: state.qrcode.get('outputFormat'),
    showSettings: state.qrcode.get('showSettings'),
    scale: state.qrcode.get('scale'),
    dark: state.qrcode.get('dark'),
    dataDark: state.qrcode.get('dataDark'),
    dataLight: state.qrcode.get('dataLight'),
    disableUploadLogo: state.qrcode.get('disableUploadLogo'),
    disableUploadBackground: state.qrcode.get('disableUploadBackground'),
    disableDownload: state.qrcode.get('disableDownload'),
    qrLogo: state.qrcode.get('qrLogo'),
    qrBackground: state.qrcode.get('qrBackground'),
    addLogoError: state.qrcode.getIn(['errorMsg', 'addLogoError']),
    addBackgroundError: state.qrcode.getIn(['errorMsg', 'addBackgroundError']),
    previewPic: state.qrcode.get('previewPic'),
    downloadPic: state.qrcode.get('downloadPic'),
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeOutputFormat(value) {
      dispatch(actionCreator.changeOutputFormat(value));
    },
    changeContentText(e) {
      dispatch(actionCreator.changeContentText(e.target.value));
    },
    changeScale(e) {
      dispatch(actionCreator.changeScale(e.target.value));
    },
    toggleShowSettings(toggleValue) {
      dispatch(actionCreator.toggleShowSettings(toggleValue));
    },
    changeDark(value) {
      dispatch(actionCreator.changeDark(value));
    },
    changeDataDark(value) {
      dispatch(actionCreator.changeDataDark(value));
    },
    changeDataLight(value) {
      dispatch(actionCreator.changeDataLight(value));
    },
    changeAddLogoError(value) {
      dispatch(actionCreator.changeAddLogoError(value));
    },
    changeAddBackgroundError(value) {
      dispatch(actionCreator.changeAddBackgroundError(value));
    },
    toggleLogoButton(value) {
      dispatch(actionCreator.toggleLogoButton(value));
    },
    toggleBackgroundButton(value) {
      dispatch(actionCreator.toggleBackgroundButton(value));
    },
    toggleDownloadButton(value) {
      dispatch(actionCreator.toggleDownloadButton(value));
    },
    changeQrLogo(value) {
      dispatch(actionCreator.changeQrLogo(value));
    },
    changeQrBackground(value) {
      dispatch(actionCreator.changeQrBackground(value));
    },
    changePreviewPic(value) {
      dispatch(actionCreator.changePreviewPic(value));
    },
    changeDownloadPic(value) {
      dispatch(actionCreator.changeDownloadPic(value));
    }

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QrcodePanel);