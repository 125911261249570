import { fromJS } from 'immutable';
import * as actionType from './actionTypes';
import i18n from 'i18next';

const languageList = ["en", "fr", "zh-CN", "ja"];
var defaultLanguage = "";

if (localStorage.getItem("i18nextLng")) 
{
  defaultLanguage = localStorage.getItem("i18nextLng");
  console.log("default langue ", defaultLanguage);
} else if (languageList.indexOf(navigator.language) > -1)
{
  defaultLanguage = navigator.language;
  console.log("navigator langue ", defaultLanguage);
} else{
  defaultLanguage = "en";
}



const defaultState = fromJS({
  // langue: navigator.language,
  langue: i18n.language || window.localStorage.i18nextLng,
});

export default (state=defaultState, action) => {
  switch (action.type) {
    case actionType.CHANGE_LANGUAGE:
      return state.set('langue', action.value);
    default:
      return state;
  }
}