
import { combineReducers } from 'redux';
import { reducer as qrcodeReducer } from '../qrcodePanel/store';
import { reducer as headerReducer } from '../header/store';
import { reducer as contactReducer } from '../contactBoard/store';
import { reducer as translatorReducer } from '../audioTranslator/store';


export default combineReducers({
    qrcode: qrcodeReducer,
    header: headerReducer,
    contact: contactReducer,
    translator: translatorReducer,
})