import * as actionType from './actionTypes';

export const changeSourceLang = (value) => ({
  'type': actionType.CHANGE_SOURCE_LANG,
  'value': value
});

export const changeTargetLang = (value) => ({
  'type': actionType.CHANGE_TARGET_LANG,
  'value': value
});

export const toggleIsRecording = (value) => ({
  'type': actionType.TOGGLE_IS_RECORDING,
  'value': value
});

export const changeSourceText = (value) => ({
  'type': actionType.CHANGE_SOURCE_TEXT,
  'value': value
});

export const changeResultText = (value) => ({
  'type': actionType.CHANGE_RESULT_TEXT,
  'value': value
});