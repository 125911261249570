import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import * as actionCreator from './store/actionCreators';
import { ContactDiv, InnerDiv } from './style';

const backendUrl = "https://spring-cat.online";

const Contact = (props) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    const formData = new FormData();
    if (typeof props.contactTitle === "string") {
      formData.append("contactTitle", props.contactTitle);
    }
    if (typeof props.contactEmail === "string") {
      if (String(props.contactEmail).match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
        formData.append("contactEmail", props.contactEmail);
      }
      else
      {
        alert("Invalid email address");
        return;
      }
    }
    else
    {
      alert("Invalid email address");
      return;
    }
    if (typeof props.contactContent === "string") {
      formData.append("contactContent", props.contactContent);
    }
    else
    {
      alert("Please tell me what you want");
      return;
    }
    try {
      const response = await axios({
        method: "POST",
        url: `${backendUrl}/api/v0/contact`,
        data: formData,
        headers: {"Content-Type": "multipart/form-data"},
      });
      if (response.status === 200) {
        alert(t('contact.send_success'));
      } else {
        alert(t('contact.send_fail'));
      }
    } catch(error) {
      console.log(error);
      alert(t('contact.send_fail'));
    }
    setOpen(false);
  }

  return (
    <div>
      <ContactDiv onClick={handleClickOpen}><InnerDiv>{t('contact.contact')}</InnerDiv></ContactDiv>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>{t('contact.contact')}</DialogTitle>
        <DialogContent>
          <TextField 
            name="email_title"
            label={t('contact.email_topic')}
            variant="outlined"
            fullWidth
            margin="normal"
            value={props.contactTitle}
            onChange={props.changeContactTitle}
          />
          <TextField
            name="email_address"
            label={t('contact.email_address')}
            variant="outlined"
            fullWidth
            margin="normal"
            value={props.contactEmail}
            onChange={props.changeContactEmail}
          />
          <TextField
            margin="normal"
            variant="outlined"
            name="email_content"
            multiline
            minRows={5}
            label={t('contact.email_content')}
            fullWidth
            placeholder={t('contact.email_content')}
            value={props.contactContent}
            onChange={props.changeContactContent}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('contact.cancel')}</Button>
          <Button onClick={handleSubmit}>{t('contact.submit')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    contactEmail: state.contact.get('contactEmail'),
    contactTitle: state.contact.get('contactTitle'),
    contactContent: state.contact.get('contactContent'),

  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeContactEmail(e) {
      dispatch(actionCreator.changeContactEmail(e.target.value));
    },
    changeContactTitle(e) {
      dispatch(actionCreator.changeContactTitle(e.target.value));
    },
    changeContactContent(e) {
      dispatch(actionCreator.changeContactContent(e.target.value));
    },

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
