import { fromJS } from 'immutable';
import * as actionType from './actionTypes';

const defaultState = fromJS({
  contactEmail: "",
  contactTitle: "",
  contactContent: "",
})

export default (state=defaultState, action) => {
  switch (action.type) {
    case actionType.CHANGE_CONTACT_EMAIL:
      return state.set('contactEmail', action.value);
    case actionType.CHANGE_CONTACT_TITLE:
      return state.set('contactTitle', action.value);
    case actionType.CHANGE_CONTACT_CONTENT:
      return state.set('contactContent', action.value);
    default:
      return state;
  }
}