

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
// import i18n from 'i18next';

import * as actionCreator from './store/actionCreators';

const LangSelector = (props) => {
  const { t, i18n } = useTranslation();

  const handleChange = (e) => {
    i18n.changeLanguage(e.target.value);
    console.log(i18n.language);
    props.changeLanguage(e.target.value);
    localStorage.setItem("i18nextLng", e.target.value);
    // alert(localStorage.getItem("i18nextLng"));
    window.location.reload();
  };

  const langDict = {
    "en": "English",
    "fr": "Français",
    "zh-CN": "简体中文",
    "ja": "日本語"
  };

  return (
    <Fragment>
      <FormControl sx={{ m: 1, minWidth: 120 }} style={{float: "right"}}>
        <Select
          value={props.langue}
          onChange={(e)=>handleChange(e)}
          style={{color: "#ffffff"}}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="en" selected={props.langue === "en" ? true: false}>English</MenuItem>
          <MenuItem value="fr" selected={props.langue === "fr" ? true: false}>Français</MenuItem>
          <MenuItem value="zh-CN" selected={props.langue === "zh-CN" ? true: false}>简体中文</MenuItem>
          <MenuItem value="ja" selected={props.langue === "ja" ? true: false}>日本語</MenuItem>
        </Select>
      </FormControl>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    langue: state.header.get('langue'),
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage(value) {
      dispatch(actionCreator.changeLanguage(value));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LangSelector);