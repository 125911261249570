import React from 'react';
import { Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { LANGUAGE_OPTIONS } from '../utils/constants';
// import { useTranslation } from 'react-i18next';


const LanguageGrid = ({ name, value, onChange }) => {
  // const { t, i18n } = useTranslation();
  const handleChange = (event) => {
    onChange(event.target.value);
  }

  return (

        <Select
          labelId={name}
          value={value}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{ height: '56px', minWidth: '18vw', backgroundColor: '#fefefe', margin: 'auto'}}
          >
          {Object.entries(LANGUAGE_OPTIONS).map(([k, v]) => {
            return (
              <MenuItem key={k} value={k}>
                {v}
              </MenuItem>
            );
          })}
          
        </Select>

  )
}

export default LanguageGrid;
