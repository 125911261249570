import { fromJS } from 'immutable';
import * as actionType from './actionTypes';

const defaultState = fromJS({
  sourceLang: "",
  targetLang: "",
  isRecording: false,
  sourceText: "",
  resultText: "",
});

export default (state=defaultState, action) => {
  switch (action.type) {
    case actionType.CHANGE_SOURCE_LANG:
      return state.set('sourceLang', action.value);
    case actionType.CHANGE_TARGET_LANG:
      return state.set('targetLang', action.value);
    case actionType.TOGGLE_IS_RECORDING:
      return state.set('isRecording', action.value);
    case actionType.CHANGE_SOURCE_TEXT:
      return state.set('sourceText', action.value);
    case actionType.CHANGE_RESULT_TEXT:
      return state.set('resultText', action.value);
    
    default:
      return state;
  }
}