export const LANGUAGE_OPTIONS = {
  "zh-CN": "中文",
  "zh-TW": "繁体中文",
  "ar": "عربي",
  "bg": "български",
  "da": "dansk",
  "nl": "Nederlands",
  "en": "English",
  "eo": "Esperanto",
  "fi": "Suomalainen",
  "fr": "Français",
  "de": "Deutsch",
  "el": "Ελληνικά",
  "it": "italiano",
  "ja": "日本語",
  "ko": "한국인",
  "la": "Latinus",
  "ms": "Melayu",
  "mn": "Монгол",
  "pt": "Português",
  "ru": "Русский",
  "es": "español",
  "sv": "svenska",
  "th": "แบบไทย",
  "tr": "Türkçe",
  "vi": "Tiếng Việt",
}