import React from 'react';
import { Globalstyle } from './style';
import Qrcode from './Qrcode';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import store from './store';
import AudioTranslator from './audioTranslator/AudioTranslator';

const App = (
    <React.StrictMode>
      <Provider store={store}>
      <Globalstyle />
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<AudioTranslator/>} />
          <Route exact path='/audio-translator' element={<AudioTranslator/>} />
          <Route exact path='/qrgarden' element={<Qrcode/>} />        
        </Routes>
      </BrowserRouter>
      </Provider>
    </React.StrictMode>
);

export default App;